<template>
  <esmp-table-wrapper
    class="privilege"
    title="Управление привилегированными пользователями"
    :is-column-settings-button-showed="false"
    :is-allow-full-page="false"
  >
    <template #header-actions>
      <div class="privilege__actions">
        <esmp-input
          label="Поиск по пользователям"
          size="small"
          icon="search"
          clearable
          v-model="query"
          @enter-exact="searchElement"
          @button-click="searchElement"
          @cleared="clearSearchElement"
        />
        <esmp-button
          v-if="pageCount > 1"
          size="small"
          @click="showAllUsers"
        >
          {{ isAllUserShowed ? 'Вернуть постраничный вывод' : 'Показать всех' }}
        </esmp-button>
        <esmp-button
          v-if="hasAccess"
          size="small"
          @click="showModal"
        >
          Добавить
        </esmp-button>
      </div>
    </template>
    <esmp-table
      :columns="columns"
      :rows="isAllUserShowed ? currentRows : paginatedPrivileges"
      :loading="isLoading"
      stripe
    >
      <template #cell-actions="{ tr }">
        <esmp-button
          v-if="hasAccess"
          icon="trash"
          size="small"
          view="outline"
          icon-position="left"
          @click="removeUser(tr)"
        >
          Удалить
        </esmp-button>
      </template>
    </esmp-table>
    <esmp-pagination-adaptive
      v-if="!isAllUserShowed"
      class="privilege__pagination"
      :current-page.sync="currentPage"
      :page-count="pageCount"
    />
    <esmp-modal
      v-model="isModalShowed"
      @on-ok="addUser"
    >
      <template #header>
        Добавить привилегированного пользователя
      </template>
      <validation-observer ref="form">
        <validation-provider
          rules="required"
          :name="`«Пользователь»`"
          v-slot="v"
          tag="div"
        >
          <esmp-select
            v-model="selectedUser"
            class="user-search"
            placeholder="Выберите пользователя"
            :remote-method="debouncedSearchUsers"
            :loading="searchLoading"
            filterable
            clearable
          >
            <esmp-select-option
              class="settings__user-search__item"
              v-for="item in searchResults"
              :value="item.login"
              :key="item.login"
              :label="item.fullName"
            >
              <span class="settings__user-search__item-name">
                {{ item.fullName }}
              </span>
              <span class="settings__user-search__item-email">
                {{ item.email }}
              </span>
            </esmp-select-option>
          </esmp-select>
          <div class="settings__error-message">
            {{ v.errors.length ? v.errors[0] : '' }}
          </div>
        </validation-provider>
      </validation-observer>
    </esmp-modal>
  </esmp-table-wrapper>
</template>

<script>
import { mapActions } from 'vuex';
import settingsPage from '@/mixins/settings-page.vue';
import { getPagesCount, getPaginatedArray } from '@/utils/pagination';
import PRIVILEGES_PER_PAGE from '@/constants/privileges';

export default {
  name: 'Privilege',
  mixins: [settingsPage],
  data() {
    return {
      columns: [
        {
          title: 'ID',
          key: 'employeeId',
        },
        {
          title: 'Имя',
          key: 'fullName',
        },
        {
          title: 'Компания',
          key: 'corpStructure',
        },
        {
          title: 'Email',
          key: 'email',
        },
        {
          title: '',
          key: 'actions',
        },
      ],
      currentPage: 1,
      rows: [],
      isAllUserShowed: false,
      query: '',
      currentRows: [],
    };
  },
  computed: {
    pageCount() {
      return getPagesCount(this.currentRows?.length, PRIVILEGES_PER_PAGE);
    },
    paginatedPrivileges() {
      return getPaginatedArray(this.currentRows, this.currentPage, PRIVILEGES_PER_PAGE);
    },
  },
  watch: {
    query(val) {
      if (!val) this.clearSearchElement();
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    getPrivilegedUsers() {
      this.isLoading = true;
      this.$API.user.getPrivilegedUsers()
        .then(({ data }) => {
          this.rows = data;
          this.currentRows = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    addUser() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          // проверка дублей
          if (this.checkHasSame(this.rows, this.selectedUser)) {
            return;
          }

          const selectedUserLogin = this.getSelectedUserLogin(this.searchResults, this.selectedUser);
          if (!selectedUserLogin) {
            this.$EsmpNotify.$show('Невозможно получить данные выбранного пользователя', 'error');
            return;
          }

          this.setLoading({ key: 'page', value: true });

          this.$API.user.addPrivilegedUser(
            this.selectedUser,
            this.searchResults.find((el) => el.login === this.selectedUser)?.login,
          )
            .then(() => {
              this.getPrivilegedUsers();
            })
            .finally(() => {
              this.$refs.form.reset();
              this.isModalShowed = false;
              this.selectedUser = '';
              this.searchResults = [];
              this.setLoading({ key: 'page', value: false });
            });
        }
      });
    },
    removeUser({ employeeId, login }) {
      this.isLoading = true;
      this.$API.user.removePrivilegedUser(employeeId, login)
        .then(() => {
          this.rows = this.rows.filter((el) => el.employeeId !== employeeId);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showAllUsers() {
      this.isAllUserShowed = !this.isAllUserShowed;
    },
    searchElement() {
      this.setLoading({ key: 'page', value: true });
      const query = this.query.trim().toLowerCase();

      this.currentRows = this.rows.filter((user) => user.fullName?.toLowerCase()?.includes(query)
        || user.email?.toLowerCase()?.includes(query));
      this.currentPage = 1;

      this.setLoading({ key: 'page', value: false });
    },
    clearSearchElement() {
      this.query = '';
      this.currentRows = this.rows;
      this.currentPage = 1;
    },
  },
  created() {
    this.getPrivilegedUsers();
  },
};
</script>

<style lang="scss">
.privilege {
  &__actions {
    display: flex;
    align-items: center;
    gap: $base-gutter;
  }
  &__pagination {
    justify-content: center;
    margin: $base-gutter 0;
  }
}
</style>
